// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cautare-tsx": () => import("./../src/pages/cautare.tsx" /* webpackChunkName: "component---src-pages-cautare-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-harta-tsx": () => import("./../src/pages/harta.tsx" /* webpackChunkName: "component---src-pages-harta-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-network-error-tsx": () => import("./../src/pages/network-error.tsx" /* webpackChunkName: "component---src-pages-network-error-tsx" */),
  "component---src-pages-nevoi-covid-tsx": () => import("./../src/pages/nevoi-covid.tsx" /* webpackChunkName: "component---src-pages-nevoi-covid-tsx" */),
  "component---src-pages-nevoijudetecovid-19-tsx": () => import("./../src/pages/nevoijudetecovid19.tsx" /* webpackChunkName: "component---src-pages-nevoijudetecovid-19-tsx" */),
  "component---src-pages-noutati-tsx": () => import("./../src/pages/noutati.tsx" /* webpackChunkName: "component---src-pages-noutati-tsx" */),
  "component---src-pages-statistici-tsx": () => import("./../src/pages/statistici.tsx" /* webpackChunkName: "component---src-pages-statistici-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

